import { Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import axios from '../../../axios/AxiosInterceptors';
import { onError } from '../../../store/actions/popupActions';
import useStyles from "../../../Styles";
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';
import { cleanStringInput } from '../../../Utility/StringUtil';

const GeneratorUnitComponent = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const accountId = props.accountId;
    const generatorId = props.generatorId;
    const disableFields = !props.isEdit;
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const [generatorUnit, setGeneratorUnit] = useState({ useInschedule: false, useSolarForecast: false, useSavedDaOffer: false, autoSubmit: false, submitFiveMinute: false, sendViaEmail: false, submitToPjm: false });
    const [solarForecastLocations, setSolarForecastLocations] = useState([{ id: 0, name: '' }]);
    const [solarForecastLocation, setSolarForecastLocation] = useState({ id: 0, name: '' });
    const [emailSenders, setEmailSenders] = useState([{ id: 0, displayName: '', internalName: '' }]);
    const [emailSender, setEmailSender] = useState({ id: 0, displayName: '', internalName: '' });
    const [emailConfiguration, setEmailConfiguration] = useState({ id: 0, toAddress: '', ccAddress: '' });

    useEffect(() => {
        if (generatorId) {
            retrieveGenerator(accountId, generatorId);
        }
        setSolarForecastLocations([{ id: 0, name: '' }]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])


    const retrieveGenerator = async (accountId, generatorId) => {
        await trackPromise(axios.get("/marketsgateway/v1/account/" + accountId + "/generation/unit/" + generatorId).then(response => {
            setGeneratorUnit(response.data);
            if (response.data.useSolarForecast) {
                retrieveSolarForecastLocations(accountId, response.data.solarForecastLocationId);
            }
            if (response.data.sendViaEmail) {
                retrieveEmailSenders(response.data.emailSenderId);
                if (response.data.emailSenderConfiguration) {
                    setEmailConfiguration({ ...response.data.emailSenderConfiguration });
                }
            }
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const retrieveSolarForecastLocations = async (accountId, selectedLocationId) => {
        await trackPromise(axios.get("/solar-forecast/account/" + accountId + "/location/detail?pageNumber=0&pageSize=1000")).then(response => {
            solarForecastLocations.push(...response.data.results);
            setSolarForecastLocations([...solarForecastLocations]);
            if (selectedLocationId) {
                let selectedLocation = solarForecastLocations.filter(r => r.id === selectedLocationId);
                if (selectedLocation && selectedLocation.length === 1) {
                    setSolarForecastLocation({ ...selectedLocation[0] });
                }
            }
        }).catch(error => {
            dispatch(onError(error.response));
        })
    }

    const retrieveEmailSenders = async (emailSenderId) => {
        await trackPromise(axios.get("/marketsgateway/v1/third-parties")).then(response => {
            emailSenders.push(...response.data);
            setEmailSenders([...emailSenders]);
            if (emailSenderId) {
                let emailSender = emailSenders.filter(r => r.id === emailSenderId);
                if (emailSender && emailSender.length === 1) {
                    setEmailSender({ ...emailSender[0] });
                }
            }
        }).catch(error => {
            dispatch(onError(error.response));
        })
    }

    const handleUnitNameChange = (e) => {
        generatorUnit.unitName = e.target.value;
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleLocationChange = (e) => {
        generatorUnit.location = e.target.value;
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleInscheduleContractIdChange = (e) => {
        if (e.target.value) {
            generatorUnit.inscheduleContractId = Number(e.target.value);
        } else {
            generatorUnit.inscheduleContractId = '';
        }
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleSolarForecastLocationChange = (e, value) => {
        setSolarForecastLocation(value);
        if (value) {
            generatorUnit.solarForecastLocationId = value.id;
        } else {
            generatorUnit.solarForecastLocationId = 0;
        }
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleEmailSenderChange = (e, value) => {
        setEmailSender(value);
        if (value) {
            generatorUnit.emailSenderId = value.id;
        } else {
            generatorUnit.emailSenderId = 0;
        }
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleSubmitToPjmChange = () => {
        generatorUnit.submitToPjm = !generatorUnit.submitToPjm;
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleSendViaEmailChange = () => {
        generatorUnit.sendViaEmail = !generatorUnit.sendViaEmail;
        if (generatorUnit.sendViaEmail) {
            retrieveEmailSenders();
        }
        if (!generatorUnit.sendViaEmail) {
            emailConfiguration.toAddress = '';
            emailConfiguration.ccAddress = '';
            generatorUnit.emailConfiguration = emailConfiguration;
            setEmailConfiguration({ ...emailConfiguration });
        }
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleAutoSubmitChange = () => {
        generatorUnit.autoSubmit = !generatorUnit.autoSubmit;
        if (!generatorUnit.autoSubmit) {
            generatorUnit.useInschedule = false;
            generatorUnit.useSolarForecast = false;
            generatorUnit.solarForecastLocationId = '';
            generatorUnit.inscheduleContractId = '';
        }
        if (generatorUnit.autoSubmit && !generatorUnit.useInschedule && !generatorUnit.useSolarForecast) {
            generatorUnit.useInschedule = true;
        }
        setGeneratorUnit({ ...generatorUnit });
    }

    const saveGenerator = () => {
        if (generatorUnit.solarForecastLocationId === 0) {
            generatorUnit.solarForecastLocationId = undefined;
        }
        if (!generatorUnit.sendViaEmail) {
            generatorUnit.emailSenderConfiguration = undefined;
        }
        setGeneratorUnit({ ...generatorUnit });
        if (generatorId) {
            updateGenerator();
        } else {
            createGenerator();
        }
    }

    const createGenerator = async () => {
        await trackPromise(axios.post("/marketsgateway/v1/account/" + accountId + "/generation/unit", JSON.stringify(generatorUnit)).then(response => {
            history.push("/markets/account/" + accountId + "/energy/generator/unit");
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const updateGenerator = async () => {
        await trackPromise(axios.put("/marketsgateway/v1/account/" + accountId + "/generation/unit/" + generatorId, JSON.stringify(generatorUnit)).then(response => {
            history.push("/markets/account/" + accountId + "/energy/generator/unit");
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const determineDaSubmissionOption = () => {
        if (generatorUnit.useInschedule) {
            return "useInschedule";
        }
        if (generatorUnit.useSolarForecast) {
            return "useSolarForecast";
        }
        if (generatorUnit.useSavedDaOffer) {
            return "useSavedDaOffer";
        }
    }

    const handleDaSubmissionOptionChange = (e) => {
        if (e.target.value === "useInschedule") {
            generatorUnit.useInschedule = true;
            generatorUnit.useSolarForecast = false;
            generatorUnit.solarForecastLocationId = '';
        } else if (e.target.value === "useSolarForecast") {
            generatorUnit.useInschedule = false;
            generatorUnit.useSolarForecast = true;
            generatorUnit.inscheduleContractId = '';
            setSolarForecastLocation({ id: 0, name: '' });
            if (generatorUnit.useSolarForecast) {
                retrieveSolarForecastLocations(accountId);
            }
        } else if (e.target.value === "useSavedDaOffer") {
            generatorUnit.useSavedDaOffer = true;
            generatorUnit.useInschedule = false;
            generatorUnit.useSolarForecast = false;
            generatorUnit.inscheduleContractId = '';
            generatorUnit.solarForecastLocationId = '';
        }
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleToAddressChange = (e) => {
        if (e.target.value) {
            emailConfiguration.toAddress = e.target.value;
        } else {
            emailConfiguration.toAddress = '';
        }
        setEmailConfiguration({ ...emailConfiguration });
        generatorUnit.emailSenderConfiguration = emailConfiguration;
        setGeneratorUnit({ ...generatorUnit });
    }

    const handleCcAddressChange = (e) => {
        if (e.target.value) {
            emailConfiguration.ccAddress = e.target.value;
        } else {
            emailConfiguration.ccAddress = '';
        }
        setEmailConfiguration({ ...emailConfiguration });
        generatorUnit.emailSenderConfiguration = emailConfiguration;
        setGeneratorUnit({ ...generatorUnit });
    }

    const renderDaSubmissionOptions = () => {
        if (generatorUnit.autoSubmit) {
            return (
                <FormControl>
                    <RadioGroup value={determineDaSubmissionOption()} onChange={handleDaSubmissionOptionChange}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={1} />
                            <Grid item xs={6} sm={4}>
                                <FormControlLabel control={<Radio />} value="useInschedule"
                                    label="Use Inschedule Contract" disabled={disableFields} />
                            </Grid>
                            <Grid item xs={6} sm={2} />
                            <Grid item xs={6} sm={4}>
                                <TextField name="inscheduleContractId" value={cleanStringInput(generatorUnit.inscheduleContractId)} label="Inschedule Contract Id" onChange={handleInscheduleContractIdChange} type='number' fullWidth disabled={!generatorUnit.useInschedule || disableFields} />
                            </Grid>
                            <Grid item xs={6} sm={1} />
                            <Grid item xs={6} sm={1} />
                            <Grid item xs={6} sm={4}>
                                <FormControlLabel control={<Radio />} value="useSolarForecast"
                                    label="Use Solar Forecast" disabled={disableFields} />
                            </Grid>
                            <Grid item xs={6} sm={2} />
                            <Grid item xs={6} sm={4}>
                                <Autocomplete name="solarForecastLocation" value={solarForecastLocation} options={solarForecastLocations}
                                    getOptionLabel={(option) => option.name} getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(e, value) => handleSolarForecastLocationChange(e, value)} renderInput={(params) => <TextField {...params} label="Solar Forecast Location" />} disabled={!generatorUnit.useSolarForecast || disableFields} />
                            </Grid>
                            <Grid item xs={6} sm={1} />
                            <Grid item xs={6} sm={1} />
                            <Grid item xs={6} sm={4}>
                                <FormControlLabel control={<Radio />} value="useSavedDaOffer" label="Use Saved Offer" disabled={disableFields} />
                            </Grid>
                            <Grid item xs={6} sm={7} />
                            <Grid item xs={6} sm={1} />
                            <Grid item xs={6} sm={4}>
                                <FormControlLabel control={<Checkbox checked={generatorUnit.sendViaEmail} onChange={handleSendViaEmailChange} />}
                                    label="Send Via Email" disabled={disableFields} />
                            </Grid>
                            <Grid item xs={6} sm={2} />
                            <Grid item xs={6} sm={4}>
                                <Autocomplete name="emailSender" value={emailSender} options={emailSenders}
                                    getOptionLabel={(option) => option.displayName} getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(e, value) => handleEmailSenderChange(e, value)} renderInput={(params) => <TextField {...params}
                                        label="Email Sender" />} disabled={!generatorUnit.sendViaEmail || disableFields} />
                            </Grid>
                            <Grid item xs={6} sm={1} />
                            {generatorUnit.sendViaEmail &&
                                <React.Fragment>
                                    <Grid item xs={6} sm={1} />
                                    <Grid item xs={6} sm={4}>
                                        <TextField name="toAddress" value={cleanStringInput(emailConfiguration.toAddress)} label="Send Email To" onChange={handleToAddressChange} fullWidth disabled={!generatorUnit.sendViaEmail || disableFields} />
                                    </Grid>
                                    <Grid item xs={6} sm={2} />
                                    <Grid item xs={6} sm={4}>
                                        <TextField name="ccAddress" value={cleanStringInput(emailConfiguration.ccAddress)} label="Send Email CC" onChange={handleCcAddressChange} fullWidth disabled={!generatorUnit.sendViaEmail || disableFields} />
                                    </Grid>
                                    <Grid item xs={6} sm={1} />
                                </React.Fragment>}
                            <Grid item xs={6} sm={1} />
                            <Grid item xs={6} sm={4}>
                                <FormControlLabel control={<Checkbox checked={generatorUnit.submitToPjm} onChange={handleSubmitToPjmChange} />}
                                    label="Submit Offers to PJM" disabled={disableFields} />
                            </Grid>
                            <Grid item xs={6} sm={7} />
                        </Grid>
                    </RadioGroup>
                </FormControl>
            )
        }
    }

    return (
        <main className={classes.layout}>
            {generatorUnit && <Typography variant="h5" align="center">{generatorUnit.unitName}</Typography>}
            <form onSubmit={(e) => {
                e.preventDefault();
                saveGenerator();
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4}>
                        <TextField name="unitName" value={cleanStringInput(generatorUnit.unitName)} label="Unit Name" onChange={handleUnitNameChange} fullWidth disabled={disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={2} />
                    <Grid item xs={6} sm={4}>
                        <TextField name="location" value={cleanStringInput(generatorUnit.location)} label="Location Id" onChange={handleLocationChange} fullWidth disabled={disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        <FormControlLabel control={<Checkbox checked={generatorUnit.autoSubmit} onChange={handleAutoSubmitChange} />} label="Submit DA Offers" disabled={disableFields} />
                    </Grid>
                    <Grid item xs={6} sm={4} />
                    {renderDaSubmissionOptions()}
                    <Grid item xs={6} sm={4} />
                    <Grid item xs={6} sm={4}>
                        {(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_generator_unit') || hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_generator_unit')) && <Button type="submit" fullWidth disabled={disableFields}>Save</Button>}
                    </Grid>
                    <Grid item xs={6} sm={4} />
                </Grid>
            </form>
        </main>
    )

}

export default GeneratorUnitComponent;