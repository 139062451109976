import React, { useEffect } from 'react';
import Loader from 'react-promise-loader';
import { usePromiseTracker } from 'react-promise-tracker';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import WpecAlert from './components/Alert/WpecAlert';
import WPECAppBar from "./components/AppBar/WPECAppBar";
import DayAheadMarketResults from './components/EnergyMarkets/DayAheadMarketResults';
import DayAheadOffer from './components/EnergyMarkets/DayAheadOffer/DayAheadOffer';
import EnergyMarketDataRetrievals from './components/EnergyMarkets/EnergyMarketDataRetrievals';
import CreateGeneratorUnit from './components/EnergyMarkets/GeneratorUnit/CreateGeneratorUnit';
import ViewGeneratorUnit from './components/EnergyMarkets/GeneratorUnit/ViewGeneratorUnit';
import ViewGeneratorUnits from './components/EnergyMarkets/GeneratorUnit/ViewGeneratorUnits';
import UnitDetail from './components/EnergyMarkets/UnitDetails/UnitDetail';
import UnitDetails from './components/EnergyMarkets/UnitDetails/UnitDetails';
import ViewEqrSubmissionDetails from './components/FercEqr/EqrSubmission/ViewEqrSubmissionDetails';
import ViewEqrSubmissions from './components/FercEqr/EqrSubmission/ViewEqrSubmissions';
import EqrTransactions from './components/FercEqr/EqrTransactions/EqrTransactions';
import ViewEqrAccount from './components/FercEqr/FercEqrAccounts/ViewEqrAccount';
import ViewEqrAccounts from './components/FercEqr/FercEqrAccounts/ViewEqrAccounts';
import CreateSupplierAccount from './components/GasSupplier/SupplierAccount/CreateSupplierAccount';
import ViewSupplierAccount from './components/GasSupplier/SupplierAccount/ViewSupplierAccount';
import ViewSupplierAccounts from './components/GasSupplier/SupplierAccount/ViewSupplierAccounts';
import CreateSupplierCustomer from './components/GasSupplier/SupplierCustomer/CreateSupplierCustomer';
import ViewSupplierCustomer from './components/GasSupplier/SupplierCustomer/ViewSupplierCustomer';
import ViewSupplierCustomers from './components/GasSupplier/SupplierCustomer/ViewSupplierCustomers';
import LoadComponent from './components/Load/LoadComponent';
import Login from './components/Login/Login';
import LogoutTimer from "./components/LogoutTimer/LogoutTimer";
import PricingComponent from "./components/Pricing/PricingComponent";
import CreateLocationDetail from './components/SolarForecast/LocationDetail/CreateLocationDetail';
import ViewLocationDetail from './components/SolarForecast/LocationDetail/ViewLocationDetail';
import ViewLocationDetails from './components/SolarForecast/LocationDetail/ViewLocationDetails';
import SolarForecast from './components/SolarForecast/SolarForecast/SolarForecast';
import CreateAccount from "./components/account-management/Account/CreateAccount";
import ViewAccount from "./components/account-management/Account/ViewAccount";
import SearchAccounts from "./components/account-management/SearchAccounts/SearchAccounts";
import CapacityCommitment from "./components/capacity-exchange/CapacityCommitment/CapacityCommitment";
import CapacityObligation from "./components/capacity-exchange/CapacityObligation/CapacityObligation";
import CreateCapacityTransferRight from "./components/capacity-exchange/CapacityTransferRight/CreateCapacityTransferRight";
import ViewCapacityTransferRight from "./components/capacity-exchange/CapacityTransferRight/ViewCapacityTransferRight";
import ViewCapacityTransferRights from "./components/capacity-exchange/CapacityTransferRight/ViewCapacityTransferRights";
import CapacityDataRetrieval from './components/capacity-exchange/DataRetrievals/CapacityDataRetrieval';
import CreateDeliveryYearAuction from "./components/capacity-exchange/DeliveryYearAuction/CreateDeliveryYearAuction";
import EditDeliveryYearAuctions from "./components/capacity-exchange/DeliveryYearAuction/EditDeliveryYearAuction";
import ViewDeliveryYearAuctions from "./components/capacity-exchange/DeliveryYearAuction/ViewDeliveryYearAuctions";
import CreateIncrementalCapacityTransferRight from "./components/capacity-exchange/IncrementalCapacityTransferRight/CreateIncrementalCapacityTransferRight";
import ViewIncrementalCapacityTransferRight from "./components/capacity-exchange/IncrementalCapacityTransferRight/ViewIncrementalCapacityTransferRight";
import ViewIncrementalCapacityTransferRights from "./components/capacity-exchange/IncrementalCapacityTransferRight/ViewIncrementalCapacityTransferRights";
import ViewCostAllocation from "./components/capacity-exchange/ProjectStatus/ViewCostAllocation";
import ViewProjectStatus from "./components/capacity-exchange/ProjectStatus/ViewProjectStatus";
import ViewProjectStatusDetail from "./components/capacity-exchange/ProjectStatus/ViewProjectStatusDetail";
import CreateResourceClearingPrices from "./components/capacity-exchange/ResourceClearingPrice/CreateResourceClearingPrices";
import EditResourceClearingPrice from "./components/capacity-exchange/ResourceClearingPrice/EditResourceClearingPrice";
import ViewResourceClearingPrices from "./components/capacity-exchange/ResourceClearingPrice/ViewResourceClearingPrices";
import AvailableResources from './components/capacity-exchange/Resources/AvailableResources';
import CreateRpmParameter from "./components/capacity-exchange/RpmParameter/CreateRpmParameter";
import EditRpmParameter from "./components/capacity-exchange/RpmParameter/EditRpmParameter";
import ViewRpmParameters from "./components/capacity-exchange/RpmParameter/ViewRpmParameters";
import CapModTransaction from './components/capacity-exchange/Transactions/CapModTransaction';
import CapacityTransactions from './components/capacity-exchange/Transactions/CapacityTransactions';
import ReplacementTransaction from './components/capacity-exchange/Transactions/ReplacementTransaction';
import UcapTransferTransaction from './components/capacity-exchange/Transactions/UcapTransferTransaction';
import UnitSpecificTransaction from './components/capacity-exchange/Transactions/UnitSpecificTransaction';
import CreateZonalAuctionResult from "./components/capacity-exchange/ZonalAuctionResult/CreateZonalAuctionResult";
import EditZonalResult from "./components/capacity-exchange/ZonalAuctionResult/EditZonalResult";
import ViewZonalAuctionResults from "./components/capacity-exchange/ZonalAuctionResult/ViewZonalAuctionResults";
import UploadMeterReadingComponent from './components/meter-reading/UploadMeterReadingComponent';
import CreateHistoricalPeaks from './components/peak-management/HistoricalPeaks/CreateHistoricalPeaks';
import HistoricalPeaks from './components/peak-management/HistoricalPeaks/HistoricalPeaks';
import PeakManagementAction from "./components/peak-management/PeakManagement/PeakManagementAction";
import BillingEstimate from './components/pjm-billing/BillingEstimate';
import CreateInvoiceReconciliation from './components/pjm-billing/CreateInvoiceReconciliation';
import PjmBilling from './components/pjm-billing/PjmBilling';
import ViewInvoiceReconciliation from './components/pjm-billing/ViewInvoiceReconciliation';
import CreateAccountRole from "./components/user-management/CreateRoles/CreateAccountRole";
import CreateApplicationRole from "./components/user-management/CreateRoles/CreateApplicationRole";
import CreateUser from "./components/user-management/CreateUser/CreateUser";
import ResetPassword from "./components/user-management/ResetPassword/ResetPassword";
import ChangePassword from "./components/user-management/UserProfile/ChangePassword";
import UserProfile from "./components/user-management/UserProfile/UserProfile";
import ViewAccountRole from "./components/user-management/ViewRoles/ViewAccountRole";
import ViewAccountRoles from "./components/user-management/ViewRoles/ViewAccountRoles";
import ViewApplicationRole from "./components/user-management/ViewRoles/ViewApplicationRole";
import ViewApplicationRoles from "./components/user-management/ViewRoles/ViewApplicationRoles";
import ViewUser from "./components/user-management/ViewUsers/ViewUser";
import ViewUsers from './components/user-management/ViewUsers/ViewUsers';
import { doAutoLogin } from './store/actions/authActions';

const App = (props) => {

    const [currentUrl, setCurrentUrl] = React.useState();

    useEffect(() => {
        if (props.location.pathname !== '/login') {
            setCurrentUrl('/');
        }
        if (props.location.pathname !== '/user-management/account/users/resetPassword') {
            props.autoLogin();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let routes = (
        <Switch>
            <Route exact path="/user-management/account/users/resetPassword" component={ResetPassword} />
            <Route path="/login" component={Login} />
            <Redirect to="/login" />
        </Switch>
    );

    if (props.isAuthenticated) {
        routes = (
            <Switch>
                <Route exact path="/logout" component={Login} />
                <Route path="/user-management/account/:accountId/users/create" component={CreateUser} />
                <Route path="/user-management/account/:accountId/users/:userId" component={ViewUser} />
                <Route exact path="/user-management/account/:accountId/users" component={ViewUsers} />
                <Route exact path="/user-management/account/:accountId/roles/create" component={CreateAccountRole} />
                <Route path="/user-management/account/:accountId/roles/:roleId" component={ViewAccountRole} />
                <Route path="/user-management/account/:accountId/roles" component={ViewAccountRoles} />
                <Route exact path="/user-management/application/roles/create" component={CreateApplicationRole} />
                <Route path="/user-management/application/roles/:roleId" component={ViewApplicationRole} />
                <Route path="/user-management/application/roles" component={ViewApplicationRoles} />
                <Route path="/user-management/users/:username/password" component={ChangePassword} />
                <Route path="/user-management/users/:username" component={UserProfile} />
                <Route path="/user-management/superuser/users/create" component={CreateUser} />
                <Route path="/user-management/superuser/users/:userId" component={ViewUser} />
                <Route exact path="/user-management/superuser/users" component={ViewUsers} />
                <Route exact path="/user-management" component={ViewUsers} />
                <Route exact path="/account-management/accounts/create" component={CreateAccount} />
                <Route path="/account-management/accounts/:accountId" component={ViewAccount} />
                <Route path="/account-management/accounts" component={SearchAccounts} />
                <Route exact path="/account-management" component={SearchAccounts} />
                <Route path="/meter-reading/account/:accountId" component={UploadMeterReadingComponent} />
                <Route exact path="/capacity/capacityTransferRights/create" component={CreateCapacityTransferRight} />
                <Route exact path="/capacity/capacityTransferRights/view" component={ViewCapacityTransferRights} />
                <Route path="/capacity/capacityTransferRights/:capacityTransferRightId" component={ViewCapacityTransferRight} />
                <Route exact path="/capacity/deliveryYearAuctions/create" component={CreateDeliveryYearAuction} />
                <Route exact path="/capacity/deliveryYearAuctions/view" component={ViewDeliveryYearAuctions} />
                <Route path="/capacity/deliveryYearAuctions/:deliveryYear" component={EditDeliveryYearAuctions} />
                <Route exact path="/capacity/projectStatus" component={ViewProjectStatus} />
                <Route path="/capacity/projectStatus/:upgradeId/costAllocation" component={ViewCostAllocation} />
                <Route exact path="/capacity/projectStatus/ictr/create" component={CreateIncrementalCapacityTransferRight} />
                <Route exact path="/capacity/projectStatus/ictr/view" component={ViewIncrementalCapacityTransferRights} />
                <Route path="/capacity/projectStatus/ictr/:iCtrId" component={ViewIncrementalCapacityTransferRight} />
                <Route path="/capacity/projectStatus/:upgradeId" component={ViewProjectStatusDetail} />
                <Route exact path="/capacity/rpmParameter/create" component={CreateRpmParameter} />
                <Route exact path="/capacity/rpmParameter/view" component={ViewRpmParameters} />
                <Route path="/capacity/rpmParameter/:rpmParameterId" component={EditRpmParameter} />
                <Route path="/capacity/resourceClearingPrice/create" component={CreateResourceClearingPrices} />
                <Route exact path="/capacity/resourceClearingPrice/view" component={ViewResourceClearingPrices} />
                <Route exact path="/capacity/resourceClearingPrice/:resourceClearingPriceId" component={EditResourceClearingPrice} />
                <Route exact path="/capacity/zonalResult/create" component={CreateZonalAuctionResult} />
                <Route exact path="/capacity/zonalResult/view" component={ViewZonalAuctionResults} />
                <Route exact path="/capacity/zonalResult/:zonalResultId" component={EditZonalResult} />
                <Route exact path="/capacity/account/:accountId/commitment" component={CapacityCommitment} />
                <Route exact path="/capacity/account/:accountId/obligation" component={CapacityObligation} />
                <Route exact path="/capacity/account/:accountId/data-retrieval" component={CapacityDataRetrieval} />
                <Route exact path="/capacity/account/:accountId/resources" component={AvailableResources} />
                <Route exact path="/capacity/account/:accountId/transactions" component={CapacityTransactions} />
                <Route path="/capacity/account/:accountId/transactions/capmod/:transactionId" component={CapModTransaction} />
                <Route path="/capacity/account/:accountId/transactions/replacement/:transactionId" component={ReplacementTransaction} />
                <Route path="/capacity/account/:accountId/transactions/unit/:transactionId" component={UnitSpecificTransaction} />
                <Route path="/capacity/account/:accountId/transactions/ucaptransfer/:transactionId" component={UcapTransferTransaction} />
                <Route path="/peak-management/historical/create" component={CreateHistoricalPeaks} />
                <Route path="/peak-management/historical" component={HistoricalPeaks} />
                <Route exact path="/peak-management" component={PeakManagementAction} />
                <Route path="/pricing" component={PricingComponent} />
                <Route path="/load" component={LoadComponent} />
                <Route exact path="/billing/account/:accountId/pjm/reconciliation/create" component={CreateInvoiceReconciliation} />
                <Route path="/billing/account/:accountId/pjm/reconciliation" component={ViewInvoiceReconciliation} />
                <Route path="/billing/account/:accountId/pjm" component={PjmBilling} />
                <Route path="/billing/account/:accountId/pjm/estimate" component={BillingEstimate} />
                <Route exact path="/ferc/eqr/eqr-account" component={ViewEqrAccounts} />
                <Route exact path="/ferc/eqr/eqr-account/:eqrAccountId" component={ViewEqrAccount} />
                <Route exact path="/ferc/eqr/eqr-account/:eqrAccountId/submissions" component={ViewEqrSubmissions} />
                <Route exact path="/ferc/eqr/eqr-account/:eqrAccountId/submissions/:generationUuid" component={ViewEqrSubmissionDetails} />
                <Route exact path="/ferc/eqr/eqr-account/:eqrAccountId/transactions" component={EqrTransactions} />
                <Route exact path="/markets/account/:accountId/energy/day-ahead" component={DayAheadMarketResults} />
                <Route exact path="/markets/account/:accountId/energy/day-ahead/offer" component={DayAheadOffer} />
                <Route exact path="/markets/account/:accountId/energy/data-retrieval" component={EnergyMarketDataRetrievals} />
                <Route exact path="/markets/account/:accountId/energy/generator/unit/details" component={UnitDetails} />
                <Route exact path="/markets/account/:accountId/energy/generator/unit" component={ViewGeneratorUnits} />
                <Route exact path="/markets/account/:accountId/energy/generator/unit/create" component={CreateGeneratorUnit} />
                <Route exact path="/markets/account/:accountId/energy/generator/unit/:location/:id" component={UnitDetail} />
                <Route exact path="/markets/account/:accountId/energy/generator/unit/:generatorId" component={ViewGeneratorUnit} />
                <Route path="/gas/supplier/account/create" component={CreateSupplierAccount} />
                <Route exact path="/gas/supplier/account/:supplierAccountId/customer/create" component={CreateSupplierCustomer} />
                <Route path="/gas/supplier/account/:supplierAccountId/customer/:id" component={ViewSupplierCustomer} />
                <Route path="/gas/supplier/account/:supplierAccountId/customer" component={ViewSupplierCustomers} />
                <Route path="/gas/supplier/account/:supplierAccountId" component={ViewSupplierAccount} />
                <Route exact path="/gas/supplier/account" component={ViewSupplierAccounts} />
                <Route exact path="/solar-forecast/account/:accountId/location" component={ViewLocationDetails} />
                <Route exact path="/solar-forecast/account/:accountId/location/create" component={CreateLocationDetail} />
                <Route path="/solar-forecast/account/:accountId/location/:locationDetailId" component={ViewLocationDetail} />
                <Route exact path="/solar-forecast/account/:accountId/forecast" component={SolarForecast} />
                <Redirect to={currentUrl ? currentUrl : '/'} />
            </Switch>
        );
    }

    return (
        <React.Fragment>
            <div>
                <WpecAlert />
                <Loader promiseTracker={usePromiseTracker} />
                {props.isAuthenticated &&
                    <React.Fragment>
                        <LogoutTimer />
                        <WPECAppBar isAuthenticated={props.isAuthenticated} key={props.chosenAccount.id} />
                    </React.Fragment>
                }
                <div style={{ paddingBottom: 40 }}>
                    {routes}
                </div>
                {props.isAuthenticated &&
                    <div>
                        <Box style={{ background: "#0F4E32", height: 25, bottom: 1, position: "fixed", width: '99%', paddingTop: 10, zIndex: 10 }}>
                            <Typography align='center' style={{ color: 'white' }}>&#169; 2025 White Pine Energy Consulting, LLC</Typography>
                        </Box>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.jwt !== null,
        chosenAccount: state.chosenAccount.account
    };
};

const mapDispatchToProps = dispatch => {
    return {
        autoLogin: () => dispatch(doAutoLogin()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));