import DateFnsUtils from '@date-io/date-fns';
import { Button, Checkbox, FormControlLabel, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import axios from "../../../axios/AxiosInterceptors";
import { onError, onSuccess } from '../../../store/actions/popupActions';
import useStyles from '../../../Styles';
import { hasPermissionForAccount } from '../../../Utility/PermissionsUtil';
import { cleanStringInput } from '../../../Utility/StringUtil';

const DayAheadOffer = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const [firstRun, setFirstRun] = useState(true);
    const [marketDay, setMarketDay] = useState(new Date());
    const [generators, setGenerators] = useState([{ id: 0, unitName: '' }]);
    const [generator, setGenerator] = useState({ id: 0, unitName: '' });
    const [copyDaOffers, setCopyDaOffers] = useState(false);
    const [copyEndDate, setCopyEndDate] = useState(new Date());
    const [dayAheadOffers, setDayAheadOffers] = useState([]);
    const [defaultEcoMinMaxEqual, setDefaultEcoMinMaxEqual] = useState(false);
    const [defaultEmergencyMinimum, setDefaultEmergencyMinimum] = useState('');
    const [defaultEconomicMinimum, setDefaultEconomicMinimum] = useState('');
    const [defaultEmergencyMaximum, setDefaultEmergencyMaximum] = useState('');
    const [defaultEconomicMaximum, setDefaultEconomicMaximum] = useState('');
    const [defaultCommitStatus, setDefaultCommitStatus] = useState('');
    const [defaultFixedGen, setDefaultFixedGen] = useState(false);

    const commitStatusOptions = [{ name: '', value: '' }, { name: 'Economic', value: 'Economic' }, { name: 'Must Run', value: 'MustRun' },
    { name: 'Unavailable', value: 'Unavailable' }];

    useEffect(() => {
        if (firstRun) {
            let date = new Date();
            date.setDate(date.getDate() + 1);
            setMarketDay(date);
            setCopyEndDate(date);
            setFirstRun(false);
        }
        if (!firstRun && marketDay instanceof Date && !isNaN(marketDay)) {
            retrieveDayAheadOffers();
            retrieveAvailableGenerators();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketDay, params, generator])

    const retrieveDayAheadOffers = async () => {
        resetDefaults();
        await trackPromise(axios.get("/marketsgateway/v1/account/" + params.accountId + "/generation/unit/" + generator.id + "/da/offer?startDate=" + moment(marketDay).format("YYYY-MM-DD") + "&endDate=" + moment(marketDay).format("YYYY-MM-DD") + "&pageNumber=0&pageSize=25").then(response => {
            if (response.data && response.data.results && response.data.results.length > 0) {
                let daOffers = response.data.results;
                for (let daOffer of daOffers) {
                    daOffer.ecoMinEqualEcoMax = false;
                }
                setDayAheadOffers([...daOffers]);
            } else {
                dayAheadOffers.splice(0, dayAheadOffers.length);
                for (let i = 0; i < 24; i++) {
                    dayAheadOffers.push({ hourBeginningDateTime: { hourBeginning: i }, hourEndingDateTime: { hourEnding: i + 1 }, ecoMinEqualEcoMax: false, emergencyMinimumMw: '', economicMinimumMw: '', emergencyMaximumMw: '', economicMaximumMw: '', commitStatus: '', fixedGen: false });
                }
                setDayAheadOffers([...dayAheadOffers]);
            }
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    const retrieveAvailableGenerators = async () => {
        await trackPromise(axios.get("/marketsgateway/v1/account/" + params.accountId + "/generation/unit?pageNumber=0&pageSize=100").then(response => {
            if (response.data.results && response.data.results.length > 0) {
                generators.push(...response.data.results);
                setGenerators([...generators]);
            }
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    const saveDayAheadOffers = async () => {
        let daOffers = dayAheadOffers;
        for (let daOffer of daOffers) {
            daOffer.accountId = params.accountId;
            daOffer.generatorUnitId = generator.id;
            if (!daOffer.hourBeginningDateTime.hourBeginningDate) {
                daOffer.hourBeginningDateTime.hourBeginningDate = moment(marketDay).format("YYYY-MM-DD");
            }
            if (!daOffer.hourEndingDateTime.hourEndingDate) {
                daOffer.hourEndingDateTime.hourEndingDate = moment(marketDay).format("YYYY-MM-DD");
            }
            daOffer.ecoMinEqualEcoMax = undefined;
        }
        await trackPromise(axios.post("/marketsgateway/v1/account/" + params.accountId + "/generation/unit/" + generator.id + "/da/offer", JSON.stringify(daOffers)).then(response => {
            let daOffers = response.data.results;
            for (let daOffer of daOffers) {
                daOffer.ecoMinEqualEcoMax = false;
            }
            if (!copyDaOffers) {
                dispatch(onSuccess("Saved Day Ahead Offers for " + generator.unitName))
            }
            setDayAheadOffers([...daOffers]);
            if (copyDaOffers) {
                copyAndSaveDaOffers(daOffers);
            }
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    const copyAndSaveDaOffers = async (originalOffers) => {
        let startDate = new Date(marketDay);
        startDate.setDate(startDate.getDate() + 1);
        let copiedOffers = [];
        let dayCounter = 1;
        while (startDate.getDate() <= copyEndDate.getDate()) {
            for (let originalOffer of originalOffers) {
                let copiedOffer = { ...originalOffer };
                copiedOffer.id = undefined;
                copiedOffer.hourBeginningDateTime = { ...originalOffer.hourBeginningDateTime };
                copiedOffer.hourEndingDateTime = { ...originalOffer.hourEndingDateTime };
                copiedOffer.hourBeginningDateTime.hourBeginningDate = moment(copiedOffer.hourBeginningDateTime.hourBeginningDate, "YYYY-MM-DD").add(dayCounter, 'days').format("YYYY-MM-DD");
                copiedOffer.hourEndingDateTime.hourEndingDate = moment(copiedOffer.hourEndingDateTime.hourEndingDate, "YYYY-MM-DD").add(dayCounter, 'days').format("YYYY-MM-DD");
                copiedOffer.ecoMinEqualEcoMax = undefined;
                copiedOffers.push(copiedOffer);
            }
            dayCounter++;
            startDate.setDate(startDate.getDate() + 1);
        }
        await trackPromise(axios.post("/marketsgateway/v1/account/" + params.accountId + "/generation/unit/" + generator.id + "/da/offer", JSON.stringify(copiedOffers)).then(response => {
            dispatch(onSuccess("Saved Day Ahead Offers for " + generator.unitName))
        })).catch(error => {
            dispatch(onError(error));
        })
    }

    const marketDayChangeHandler = (date) => {
        setMarketDay(date);
        setCopyEndDate(date);
        resetDefaults();
    }

    const moveDateBackwards = () => {
        let nextDay = new Date(marketDay);
        nextDay.setDate(marketDay.getDate() - 1);
        setMarketDay(nextDay);
        setCopyEndDate(nextDay);
        resetDefaults();
    }

    const moveDateForwards = () => {
        let nextDay = new Date(marketDay);
        nextDay.setDate(marketDay.getDate() + 1);
        setMarketDay(nextDay);
        setCopyEndDate(nextDay);
        resetDefaults();
    }

    const resetDefaults = () => {
        setDefaultEcoMinMaxEqual(false);
        setDefaultEmergencyMinimum('');
        setDefaultEmergencyMaximum('');
        setDefaultEconomicMinimum('');
        setDefaultEconomicMaximum('');
        setDefaultCommitStatus('');
        setDefaultFixedGen(false);
    }

    const handleDaCopyOffersChange = () => {
        setCopyDaOffers(!copyDaOffers);
    }

    const copyEndDateChangeHandler = (date) => {
        setCopyEndDate(date);
    }

    const findEcoMinMaxEqualValue = (hourBeginning) => {
        if (dayAheadOffers && dayAheadOffers.length > 0) {
            const daOffer = dayAheadOffers.filter(r => r.hourBeginningDateTime.hourBeginning === hourBeginning);
            if (daOffer && daOffer.length === 1) {
                return daOffer[0].ecoMinEqualEcoMax;
            }
        }
        return false;
    }

    const findDayAheadOfferValue = (fieldName, hourBeginning) => {
        if (dayAheadOffers && dayAheadOffers.length > 0) {
            const daOffer = dayAheadOffers.filter(r => r.hourBeginningDateTime.hourBeginning === hourBeginning);
            if (daOffer && daOffer.length === 1) {
                if (daOffer[0][fieldName]) {
                    return daOffer[0][fieldName];
                }
            }
        }
        return "";
    }

    const findFixedGenValue = (hourBeginning) => {
        if (dayAheadOffers && dayAheadOffers.length > 0) {
            const daOffer = dayAheadOffers.filter(r => r.hourBeginningDateTime.hourBeginning === hourBeginning);
            if (daOffer && daOffer.length === 1) {
                return daOffer[0].fixedGen;
            }
        }
        return false;
    }

    const handleEcoMinMaxEqualValue = (e, hourBeginning) => {
        let index = -1;
        if (dayAheadOffers && dayAheadOffers.length > 0) {
            for (let i = 0; i < dayAheadOffers.length; i++) {
                if (dayAheadOffers[i].hourBeginningDateTime.hourBeginning === hourBeginning) {
                    index = i;
                }
            }
        }
        if (index === -1) {
            dayAheadOffers.push({ hourBeginningDateTime: { hourBeginning: hourBeginning }, ecoMinEqualEcoMax: e.target.checked ? e.target.checked : false });
        } else {
            let dayAheadOffer = dayAheadOffers[index];
            dayAheadOffer.ecoMinEqualEcoMax = !dayAheadOffer.ecoMinEqualEcoMax;
            if (dayAheadOffer.economicMinimumMw || dayAheadOffer.economicMaximumMw) {
                if (dayAheadOffer.economicMinimumMw && !dayAheadOffer.economicMaximumMw) {
                    dayAheadOffer.economicMaximumMw = dayAheadOffer.economicMinimumMw;
                } else if (!dayAheadOffer.economicMinimumMw && dayAheadOffer.economicMaximumMw) {
                    dayAheadOffer.economicMinimumMw = dayAheadOffer.economicMaximumMw;
                } else {
                    dayAheadOffer.economicMinimumMw = dayAheadOffer.economicMaximumMw = "";
                }
            }
            dayAheadOffers.splice(index, 1, dayAheadOffer);
        }
        setDayAheadOffers([...dayAheadOffers]);
    }

    const handleFixedGenChange = (e, hourBeginning) => {
        let index = -1;
        if (dayAheadOffers && dayAheadOffers.length > 0) {
            for (let i = 0; i < dayAheadOffers.length; i++) {
                if (dayAheadOffers[i].hourBeginningDateTime.hourBeginning === hourBeginning) {
                    index = i;
                }
            }
        }
        if (index === -1) {
            dayAheadOffers.push({ hourBeginningDateTime: { hourBeginning: hourBeginning }, fixedGen: e.target.checked ? e.target.checked : false });
        } else {
            let dayAheadOffer = dayAheadOffers[index];
            dayAheadOffer.fixedGen = !dayAheadOffer.fixedGen;
            dayAheadOffers.splice(index, 1, dayAheadOffer);
        }
        setDayAheadOffers([...dayAheadOffers]);
    }

    const handleCommitStatusChange = (e, value, hourBeginning) => {
        let index = -1;
        if (dayAheadOffers && dayAheadOffers.length > 0) {
            for (let i = 0; i < dayAheadOffers.length; i++) {
                if (dayAheadOffers[i].hourBeginningDateTime.hourBeginning === hourBeginning) {
                    index = i;
                }
            }
        }
        if (index === -1) {
            dayAheadOffers.push({ hourBeginningDateTime: { hourBeginning: hourBeginning }, commitStatus: value.value });
        } else {
            let dayAheadOffer = dayAheadOffers[index];
            if (value && value.value) {
                dayAheadOffer.commitStatus = value.value;
                if (value.value === 'Unavailable') {
                    dayAheadOffer.emergencyMinimumMw = 0;
                    dayAheadOffer.emergencyMaximumMw = 0;
                    dayAheadOffer.economicMinimumMw = 0;
                    dayAheadOffer.economicMaximumMw = 0;
                }
            } else {
                dayAheadOffer.commitStatus = { name: '', value: '' };
            }
            dayAheadOffers.splice(index, 1, dayAheadOffer);
        }
        setDayAheadOffers([...dayAheadOffers]);
    }

    const handleDayAheadOfferChange = (e, fieldName, hourBeginning) => {
        let index = -1;
        if (dayAheadOffers && dayAheadOffers.length > 0) {
            for (let i = 0; i < dayAheadOffers.length; i++) {
                if (dayAheadOffers[i].hourBeginningDateTime.hourBeginning === hourBeginning) {
                    index = i;
                }
            }
        }
        if (dayAheadOffers) {
            if (index === -1) {
                let dayAheadOffer = { hourBeginningDateTime: { hourBeginning: hourBeginning } };
                dayAheadOffer[fieldName] = parseFloat(e.value);
                dayAheadOffers.push(dayAheadOffer);
            } else {
                let dayAheadOffer = dayAheadOffers[index];
                dayAheadOffer[fieldName] = parseFloat(e.value);
                if (dayAheadOffer.ecoMinEqualEcoMax && fieldName === 'economicMinimumMw') {
                    dayAheadOffer.economicMaximumMw = parseFloat(e.value);
                }
                if (dayAheadOffer.ecoMinEqualEcoMax && fieldName === 'economicMaximumMw') {
                    dayAheadOffer.economicMinimumMw = parseFloat(e.value);
                }
                dayAheadOffers.splice(index, 1, dayAheadOffer);
            }
            setDayAheadOffers([...dayAheadOffers]);
        }
    }

    const handleDefaultEcoMinMaxEqualChange = (e) => {
        setDefaultEcoMinMaxEqual(!defaultEcoMinMaxEqual);
        for (let dayAheadOffer of dayAheadOffers) {
            if (e.target.checked) {
                dayAheadOffer.economicMinimumMw = '';
                dayAheadOffer.economicMaximumMw = '';
            }
            dayAheadOffer.ecoMinEqualEcoMax = e.target.checked;
        }
        setDayAheadOffers([...dayAheadOffers]);
    }

    const handleDefaultEmergencyMinimumChange = (e) => {
        setDefaultEmergencyMinimum(parseFloat(e.value));
        for (let dayAheadOffer of dayAheadOffers) {
            dayAheadOffer.emergencyMinimumMw = parseFloat(e.value);
        }
        setDayAheadOffers([...dayAheadOffers]);
    }

    const handleDefaultEconomicMinimumChange = (e) => {
        setDefaultEconomicMinimum(parseFloat(e.value));
        if (defaultEcoMinMaxEqual) {
            setDefaultEconomicMaximum(parseFloat(e.value));
        }
        for (let dayAheadOffer of dayAheadOffers) {
            dayAheadOffer.economicMinimumMw = parseFloat(e.value);
            if (defaultEcoMinMaxEqual || dayAheadOffer.ecoMinEqualEcoMax) {
                dayAheadOffer.economicMaximumMw = parseFloat(e.value);
            }
        }
        setDayAheadOffers([...dayAheadOffers]);
    }

    const handleDefaultEmergencyMaximumChange = (e) => {
        setDefaultEmergencyMaximum(parseFloat(e.value));
        for (let dayAheadOffer of dayAheadOffers) {
            dayAheadOffer.emergencyMaximumMw = parseFloat(e.value);
        }
        setDayAheadOffers([...dayAheadOffers]);
    }

    const handleDefaultEconomicMaximumChange = (e) => {
        setDefaultEconomicMaximum(parseFloat(e.value));
        if (defaultEcoMinMaxEqual) {
            setDefaultEconomicMinimum(parseFloat(e.value));
        }
        for (let dayAheadOffer of dayAheadOffers) {
            dayAheadOffer.economicMaximumMw = parseFloat(e.value);
            if (defaultEcoMinMaxEqual || dayAheadOffer.ecoMinEqualEcoMax) {
                dayAheadOffer.economicMinimumMw = parseFloat(e.value);
            }
        }
        setDayAheadOffers([...dayAheadOffers]);
    }

    const handleDefaultCommitStatusChange = (e, value) => {
        if (value && value.value) {
            setDefaultCommitStatus(value.value);
            for (let dayAheadOffer of dayAheadOffers) {
                dayAheadOffer.commitStatus = value.value;
            }
        } else {
            setDefaultCommitStatus('')
            for (let dayAheadOffer of dayAheadOffers) {
                dayAheadOffer.commitStatus = '';
            }
        }
        setDayAheadOffers([...dayAheadOffers]);
    }

    const handleDefaultFixedGenChange = (e) => {
        setDefaultFixedGen(!defaultFixedGen);
        for (let dayAheadOffer of dayAheadOffers) {
            dayAheadOffer.fixedGen = e.target.checked;
        }
        setDayAheadOffers([...dayAheadOffers]);
    }

    const handleGeneratorChange = (event, value) => {
        setGenerator({ ...value });
    }

    const isDisabled = () => {
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);
        if (moment(marketDay).isBefore(tomorrow, 'day')) {
            return true;
        }
        if (moment(marketDay).isSame(tomorrow, 'day') && today.getHours() >= 10) {
            return true;
        }
        return false;
    }

    const renderTableRows = () => {
        const tableRows = [];
        tableRows.push(<TableRow key={"defaults"}>
            <TableCell><Checkbox checked={defaultEcoMinMaxEqual} onChange={(e) => handleDefaultEcoMinMaxEqualChange(e)} disabled={isDisabled()} /></TableCell>
            <TableCell />
            <TableCell />
            <TableCell><NumberFormat name="defaultEmergencyMinimum" value={cleanStringInput(defaultEmergencyMinimum)} customInput={TextField} thousandSeparator={true} decimalScale={1} type="text" onValueChange={(e) => handleDefaultEmergencyMinimumChange(e)} suffix=' MWs' placeholder='MWs' disabled={isDisabled()} /></TableCell>
            <TableCell><NumberFormat name="defaultEconomicMinimum" value={cleanStringInput(defaultEconomicMinimum)} customInput={TextField} thousandSeparator={true} decimalScale={1} type="text" onValueChange={(e) => handleDefaultEconomicMinimumChange(e)} suffix=' MWs' placeholder='MWs' disabled={isDisabled()} /></TableCell>
            <TableCell><NumberFormat name="defaultEconomicMaximum" value={cleanStringInput(defaultEconomicMaximum)} customInput={TextField} thousandSeparator={true} decimalScale={1} type="text" onValueChange={(e) => handleDefaultEconomicMaximumChange(e)} suffix=' MWs' placeholder='MWs' disabled={isDisabled()} /></TableCell>
            <TableCell><NumberFormat name="defaultEmergencyMaximum" value={cleanStringInput(defaultEmergencyMaximum)} customInput={TextField} thousandSeparator={true} decimalScale={1} type="text" onValueChange={(e) => handleDefaultEmergencyMaximumChange(e)} suffix=' MWs' placeholder='MWs' disabled={isDisabled()} /></TableCell>
            <TableCell><Autocomplete options={commitStatusOptions} getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option.name}
                onChange={(e, value) => handleDefaultCommitStatusChange(e, value)}
                renderInput={(params) => <TextField {...params} label="Commit Status" />} value={defaultCommitStatus}
                getOptionSelected={(option, value) => option.value === value} disabled={isDisabled()} /></TableCell>
            <TableCell><Checkbox checked={defaultFixedGen} onChange={(e) => handleDefaultFixedGenChange(e)} disabled={isDisabled()}
            /></TableCell>
        </TableRow>)
        for (let i = 0; i < 24; i++) {
            tableRows.push(
                <TableRow key={i}>
                    <TableCell>
                        <Checkbox checked={findEcoMinMaxEqualValue(i)} onChange={(e, hourBeginning) => handleEcoMinMaxEqualValue(e, i)} disabled={isDisabled()} />
                    </TableCell>
                    <TableCell>{i}</TableCell>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell><NumberFormat name="emergencyMinimum" value={findDayAheadOfferValue("emergencyMinimumMw", i)} customInput={TextField} thousandSeparator={true} decimalScale={1} type="text" onValueChange={(e, fieldName, hourBeginning) => handleDayAheadOfferChange(e, "emergencyMinimumMw", i)} suffix=' MWs' placeholder='MWs' disabled={isDisabled()} /></TableCell>
                    <TableCell><NumberFormat name="economicMinimum" value={findDayAheadOfferValue("economicMinimumMw", i)} customInput={TextField} thousandSeparator={true} decimalScale={1} type="text" onValueChange={(e, fieldName, hourBeginning) => handleDayAheadOfferChange(e, "economicMinimumMw", i)} suffix=' MWs' placeholder='MWs' disabled={isDisabled()} /></TableCell>
                    <TableCell><NumberFormat name="economicMaximum" value={findDayAheadOfferValue("economicMaximumMw", i)} customInput={TextField} thousandSeparator={true} decimalScale={1} type="text" onValueChange={(e, fieldName, hourBeginning) => handleDayAheadOfferChange(e, "economicMaximumMw", i)} suffix=' MWs' placeholder='MWs' disabled={isDisabled()} /></TableCell>
                    <TableCell><NumberFormat name="emergencyMaximum" value={findDayAheadOfferValue("emergencyMaximumMw", i)} customInput={TextField} thousandSeparator={true} decimalScale={1} type="text" onValueChange={(e, fieldName, hourBeginning) => handleDayAheadOfferChange(e, "emergencyMaximumMw", i)} suffix=' MWs' placeholder='MWs' disabled={isDisabled()} /></TableCell>
                    <TableCell>
                        <Autocomplete options={commitStatusOptions} getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option.name}
                            onChange={(e, value, hourEnding) => handleCommitStatusChange(e, value, i)}
                            renderInput={(params) => <TextField {...params} label="Commit Status" />} value={findDayAheadOfferValue("commitStatus", i)}
                            getOptionSelected={(option, value) => option.value === value} disabled={isDisabled()} /></TableCell>
                    <TableCell><Checkbox checked={findFixedGenValue(i)} onChange={(e, hourBeginning) => handleFixedGenChange(e, i)} disabled={isDisabled()}
                    /></TableCell>
                </TableRow>
            )
        }
        return tableRows;
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div>
                        <IconButton onClick={moveDateBackwards}>
                            <ArrowLeft />
                        </IconButton>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Market Day" name="marketDay"
                                onChange={(date) => marketDayChangeHandler(date)} value={marketDay} autoOk={true} />
                        </MuiPickersUtilsProvider>
                        <IconButton onClick={moveDateForwards}>
                            <ArrowRight />
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <Autocomplete value={generator} options={generators} getOptionLabel={(option) => option ? option.unitName : ''}
                        renderInput={(params) => <TextField {...params} label="Unit Name" />} onChange={(event, value) => handleGeneratorChange(event, value)}
                        getOptionSelected={(option, value) => option.id === value.id} />
                </Grid>
            </Grid>
            <TableContainer className={classes.tableContainer}>
                <Table sx={{ width: "max-content" }} stickyHeader className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Eco Max equal Eco Min</TableCell>
                            <TableCell>Hour Beginning</TableCell>
                            <TableCell>Hour Ending</TableCell>
                            <TableCell>Emergency Minimum</TableCell>
                            <TableCell>Economic Minimum</TableCell>
                            <TableCell>Economic Maximum</TableCell>
                            <TableCell>Emergency Maximum</TableCell>
                            <TableCell width={150}>Commit Status</TableCell>
                            <TableCell>Fixed Gen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2}>
                <Grid item xs={3} />
                <Grid item xs={3}>
                    <FormControlLabel control={<Checkbox checked={copyDaOffers} onChange={handleDaCopyOffersChange} />}
                        label="Copy DA Offers Until" disabled={isDisabled()} />
                </Grid>
                <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Copy End Date" name="copyEndDate"
                            onChange={(date) => copyEndDateChangeHandler(date)} value={copyEndDate} autoOk={true} disabled={!copyDaOffers} disablePast={true} minDate={marketDay} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={4} />
                <Grid item xs={4} align="center">
                    {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_da_generation_offer') && <Button onClick={() => saveDayAheadOffers()} variant="contained" disabled={isDisabled()}>Save</Button>}
                </Grid>
                <Grid item xs={4} />
            </Grid>
        </main>
    )
}

export default DayAheadOffer;